import styled from 'styled-components';
import { Link } from "gatsby-plugin-intl";
import { mediaMin } from 'common/styledMixins';
import { color, breakpoint } from 'common/styledVars';
import { HeaderMedium, BaseTransparentP } from 'components/typography';
import RoundButton from 'components/Button/RoundButton';
import { WidthWrapper } from 'components/wrappers';

export const WhiteContainer = styled(WidthWrapper)`
  margin-top: 30px;
  padding-bottom:30px;
  
  span strong {
    font-weight:600;
    color:#000;
  }

  ul li strong {
    color:#000;
    font-weight:600;
  }

  ${BaseTransparentP} {
    margin-bottom: 30px;
  }
`;

export const BackButton = styled(Link)`
  position: absolute;
  left: 5px;
  top: -40px;

  @media ${mediaMin(breakpoint.desktop)} {
    left: 50px;
    top: -60px;
  }
`;

export const ButtonsBox = styled.div`
  margin-left: auto;
  display: flex;

  & > ${RoundButton} {
    margin-left: 20px;
  }
`;
export const Image = styled.img`
  margin: 65px auto;
  max-width: 780px;
  width: 100%;
  object-fit: cover;
`;

export const HeaderSection = styled.div`
  padding: 74px 0 91px;
  display: flex;
  flex-direction: column;

  ${HeaderMedium} {
    color: ${color.white};
    margin: 243px 0 60px;
    max-width: 480px;
  }
`;

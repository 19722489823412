import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import HeroBg from 'images/fulfillment-centers-vs-warehouse/fulfillment-centers-vs-warehouse-hero-bg.png';
import InventoryStorageImg from 'images/fulfillment-centers-vs-warehouse/inventory-storage.png';
import EbayHoliday from 'images/holiday-shipping/ebay-holiday-deadlines.png';
import WarehouseBG from 'images/fulfillment-centers-vs-warehouse/warehouse.png';
import FulfillmentBG from 'images/fulfillment-centers-vs-warehouse/fulfillment-centers.png';
import HowTheyOperateImg from 'images/fulfillment-centers-vs-warehouse/how-they-operate.png';
import ProsIcon from 'images/fulfillment-centers-vs-warehouse/pros-icon.png';
import ConsIcon from 'images/fulfillment-centers-vs-warehouse/cons-icon.png';

import {UnorderedList} from 'components/blog/SingleBlogStyle';

import { DetailContent, PageContainer, MainSection, ShippingSectionURL, FulfillmentVsWarehouseSection }  from 'components/pages/Resources/FulfillmentVsWarehouse';

const ContentSmall = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallBg = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
  background: #FCF1DE;
  text-align: center;
  border-bottom:2px solid #000;
`;

const ContentSmallCenter = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:10px;
  text-align: center;
`;

const ContentSmallSpan = styled.span`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1300px;
  margin-left:auto;
  margin-right:auto;
`;

const TabNavigation = styled.div`
  width:25%;
  display:inline-block;

`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 20px;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;
const Breadcrumbs = styled.p`
    padding: 0px 0px 25px 0px;
    
`;

const FulfillmentVsWarehouse = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Fulfillment Centers vs. Warehouse – The Similarities and Differences | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "resources-fulfillment-centers" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "fulfillment-centers-vs-warehouse-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "fulfillment-centers-vs-warehouse-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "fulfillment-centers-vs-warehouse-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "fulfillment-centers-vs-warehouse-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content={intl.formatMessage({ id: "resources-fulfillment-centers" })} />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "resources-fulfillment-centers" })} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section" className={intl.formatMessage({ id: "language" })}>
            <img className="hero-bg" src={HeroBg} alt="fulfillment centers versus warehouse"/>
            <Row>
                <div className="column-whole">
                  <div className="content-container">
                    <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-fulfillment-centers-vs-warehouse" })}</Breadcrumbs>
                    <HeroHeader>{intl.formatMessage({ id: "fvw-header" })}</HeroHeader>
                    <HeroText>{intl.formatMessage({ id: "fvw-description" })}</HeroText>
                  </div>
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>        
        <ShippingSectionURL>
          <Row>
            <div id="tab-navigation">
              <TabNavigation>
                <a href="#differences">
                  <h3>{intl.formatMessage({ id: "fvw-differences-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#how-they-operate">
                  <h3>{intl.formatMessage({ id: "fvw-how-they-operate-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#inventory-storage">
                  <h3>{intl.formatMessage({ id: "fvw-inventory-storage-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#which-is-better">
                  <h3>{intl.formatMessage({ id: "fvw-which-is-better-tab" })}</h3>
                </a>
              </TabNavigation>
              <Clear></Clear>
            </div> 
          </Row>
        </ShippingSectionURL>
        <FulfillmentVsWarehouseSection>
          <div id="differences" className={intl.formatMessage({ id: "language" })}>
            <Row>
              <ContentSmall>
                {intl.formatMessage({ id: "fvw-differences-desc" })}
              </ContentSmall>
              <h2>
                {intl.formatMessage({ id: "fvw-differences-title" })}
              </h2>
              <div className="column-half">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "fvw-differences-warehouse-title" })}</strong>
                  <p className="hover-desc">
                    {intl.formatMessage({ id: "fvw-differences-warehouse-desc" })}
                  </p>
                </p>
                <p className="onhover">
                  {intl.formatMessage({ id: "fvw-differences-warehouse-title" })}
                </p>
                <img className="box-bg" src={WarehouseBG} alt="warehouse" />
              </div>
              <div className="column-half last">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "fvw-differences-fulfillment-title" })}</strong>
                  <p className="hover-desc">
                    {intl.formatMessage({ id: "fvw-differences-fulfillment-desc" })}
                  </p>
                </p>
                <p className="onhover">
                  {intl.formatMessage({ id: "fvw-differences-fulfillment-title" })}
                </p>
                <img className="box-bg" src={FulfillmentBG} alt="fulfillment centers"/>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </FulfillmentVsWarehouseSection>
        <FulfillmentVsWarehouseSection>
          <div id="how-they-operate">
            <Row>
              <div className="column-half">
                <img className="" src={HowTheyOperateImg} alt="how they operate" />
              </div>
              <div className="column-half last">
                <h2>
                  {intl.formatMessage({ id: "fvw-how-they-operate-title" })}
                </h2>
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "fvw-how-they-operate-warehouse-title" })}</strong>
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "fvw-how-they-operate-warehouse-desc" })}
                </ContentSmall>
                <UnorderedList>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-warehouse-list-1" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-warehouse-list-2" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-warehouse-list-3" })}</li>
                </UnorderedList>
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-title" })}</strong>
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-desc" })}
                </ContentSmall>
                <UnorderedList>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-list-1" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-list-2" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-list-3" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-list-4" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-list-5" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-how-they-operate-fulfillment-list-6" })}</li>
                </UnorderedList>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </FulfillmentVsWarehouseSection>      
        <FulfillmentVsWarehouseSection>
          <Link to="/contact">
            <ArrowButton >{intl.formatMessage({ id: "drop-a-line-button" })}</ArrowButton>
          </Link>
          <div id="inventory-storage">
            <Row>
              <div className="column-half">
                <h2>
                  {intl.formatMessage({ id: "fvw-inventory-storage-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "fvw-inventory-storage-desc-1" })} 
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "fvw-inventory-storage-desc-2" })}
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "fvw-inventory-storage-desc-3" })} 
                </ContentSmall>
              </div>
              <div className="column-half last">
                <img src={InventoryStorageImg} alt="inventory storage" />
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </FulfillmentVsWarehouseSection>
        <FulfillmentVsWarehouseSection>
          <div id="which-is-better" className={intl.formatMessage({ id: "language" })}>
            <Row>
              <h2>
                {intl.formatMessage({ id: "fvw-which-is-better-title" })}
              </h2>
              <ContentSmallCenter>
                <strong>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-title" })}</strong>
              </ContentSmallCenter>
              <div className="column-half">
                <ContentSmallBg>
                  <img src={ProsIcon} className="icon" alt="Pros Icon" /><strong>{intl.formatMessage({ id: "fvw-which-is-better-pros" })}</strong>
                </ContentSmallBg>
                <UnorderedList>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-1" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-2" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-3" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-4" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-5" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-6" })}</li>
                </UnorderedList>
              </div>
              <div className="column-half last">
                <ContentSmallBg>
                  <img src={ConsIcon} className="icon" alt="Cons Icon" /><strong>{intl.formatMessage({ id: "fvw-which-is-better-cons" })}</strong>
                </ContentSmallBg>
                <UnorderedList>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-7" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-8" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-9" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-10" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-warehouse-list-11" })}</li>
                </UnorderedList>
              </div>
              <Clear></Clear>
              <ContentSmallCenter>
                <strong>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-title" })}</strong>
              </ContentSmallCenter>
              <div className="column-half fulfillment">
                <ContentSmallBg>
                  <img src={ProsIcon} className="icon" alt="Pros Icon" /><strong>{intl.formatMessage({ id: "fvw-which-is-better-pros" })}</strong>
                </ContentSmallBg>
                <UnorderedList>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-1" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-2" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-3" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-4" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-5" })}</li>
                </UnorderedList>
              </div>
              <div className="column-half last fulfillment">
                <ContentSmallBg>
                  <img src={ConsIcon} className="icon" alt="Cons Icon" /><strong>{intl.formatMessage({ id: "fvw-which-is-better-cons" })}</strong>
                </ContentSmallBg>
                <UnorderedList>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-6" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-7" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-8" })}</li>
                  <li>{intl.formatMessage({ id: "fvw-which-is-better-fulfillment-list-9" })}</li>
                </UnorderedList>
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </FulfillmentVsWarehouseSection>
      </PageContainer>
    </Layout>
  );
};

export default FulfillmentVsWarehouse;

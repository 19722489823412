import styled from 'styled-components';

import { color } from 'common/styledVars';
import { BaseButton } from './index';

const RoundButton = styled(BaseButton)`
  width: 62px;
  height: 62px;
  border-radius: 17px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${color.black};
  }
`;

export default RoundButton;

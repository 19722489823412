import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';

export const DetailContent = styled.div`
  ${HeaderMedium} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

export const PageContainer = styled.div`
  margin-bottom:0;
  width:100%;

  .column-whole {
    position:relative;
  }

  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }

  .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    float:left;
  }

  .column-third.last {
    margin-right:0;
  }

`;

export const MainSection = styled.div`
  position:relative;

  #hero-section {
    padding:0;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  #hero-section .column-half {
    width: 50%;
    padding-right: 0;
    position: relative;
    padding-top: 45px;
    padding-bottom: 80px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #hero-section .column-whole {
    padding: 120px 0px 135px 0px;
    max-width:650px;
    margin-left:auto;
    margin-right:auto;
  }

  #hero-section .content-container {
    position: relative;
    z-index: 2;
  }

  #hero-section .column-half p{
    padding-right:60px;
  }

  #hero-section .column-half.last {
    width: 50%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #hero-section .column-half img{
      width: 100%;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left:0;
    /*object-fit: cover;*/
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  #hero-section .hero-bg-tablet {
    display:none;
  }

  #hero-section .hero-bg-mobile {
    display:none;
  }

  @media only screen and (max-width:1025px) { 
    #hero-section .column-whole {
      padding: 50px 0 50px 0;
    }

    #hero-section .hero-bg {
      object-fit: cover;
    }
  }

  @media only screen and (max-width:981px) {
    #hero-section .column-half {
      width: 100%;
      padding:0;
    }

    #hero-section .column-half img{
      width: 100%;
    }

    #hero-section .column-half p{
      padding-right:0;
    }

    #hero-section .column-half.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 25px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width:875px) {

    #hero-section .column-whole {
      padding: 0px 0px 0px 0px;
      margin-left: 0;
    }
  }
`;

export const ShippingSectionURL = styled.div`
  #tab-navigation {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  #tab-navigation h3:after {
    content: '';
    position: absolute;
    width: 60px;
    height: 4px;
    background: #05ad89;
    bottom: 0;
    left: 0;
    right: 0;
    top: 40px;
    margin: auto;
  }

  #tab-navigation h3 {
    text-align:center;
    position:relative;
    color:#4D4D4D;
    font-size: 17px;
    font-weight: 400;
  }

  @media only screen and (max-width:981px) {
    
    #tab-navigation {
      padding: 20px;
    }

    #tab-navigation div {
      width: 100%;
      padding-bottom: 45px;
    }

    #tab-navigation div:nth-child(4) {
      padding-bottom:0;
    }

  }
  
`;

export const FulfillmentVsWarehouseSection = styled.div`
  padding-top:25px;

  button {
    margin-left:auto;
    margin-right:auto;
  }

  #inventory-storage {
    max-width: 1080px;
    margin-left:auto;
    margin-right:auto;
    padding-top:40px;
    scroll-margin-top: 120px;
  }

  #inventory-storage .column-half {
    width: 65%;
    padding-right: 2%;
    position: relative;
    padding-bottom: 0px;
    display: inline-block;
    float: left;
  }

  #inventory-storage  .column-half.last {
    width: 35%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #inventory-storage img {
    margin-top:25px;
  }

  #inventory-storage h2 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:25px;
  }

  #ebay-holiday-deadlines {
    padding-bottom:70px;
  }

  #ebay-holiday-deadlines .column-half {
    width: 30%;
    padding-right: 2%;
    position: relative;
    padding-bottom: 0px;
    padding-left: 8%;
    display: inline-block;
    float: left;
    margin-top:-50px
  }

  #ebay-holiday-deadlines  .column-half.last {
    width: 70%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 50px;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #ebay-holiday-deadlines h2 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:25px;
  }

  #differences h2 {
    text-align:center;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top:40px;
  }

  #differences {
    padding-top: 0px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
  }

  #differences p{
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4D4D4D;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
  }

  #differences .column-half {
    width: 47%;
    display: inline-block;
    position: relative;
    margin-right: 3%;
    padding: 0;
    border-radius: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
    max-height: 450px;
    min-height: 450px;
    background: transparent;
    border: 0;
  }

  .onhover {
    margin-top:-10px;
  }

  #differences .column-half.last {
    margin-right:0;
  }

  #differences .column-half .box-icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-height: 75px;
    position:relative;
    z-index:2;
  }

  #differences .column-half img.box-bg { 
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    max-height: 100%;
    margin-bottom: 0;
  }

  #differences .column-half p.hover {
    z-index: 2;
    margin-top: 85px;
    color: #4d4d4d;
    margin-bottom: 5px; 
    background: rgba(255,255,255,0.75);
    padding-top: 5px;
    font-size: 18px;
  }

  #differences .column-half p.hover-desc {
    display: none;
    position: relative;
    color: #4D4D4D;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    padding-bottom:25px;
    padding-top:10px;
    text-align:left;
    padding-left:20px;
    padding-right:20px;
  }

  #differences .column-half:hover {
    cursor:pointer;
  }

  #differences .column-half:hover .box-icon{
    display:none;
    animation: fadeIn ease 1s;
  }

  #differences .column-half:hover p.hover-desc{
    display:block;
    animation: fadeIn ease 1s;
  }

  #differences .column-half:hover p.hover{
    /*display:none;*/
    /*animation: fadeIn ease 1s;*/
    min-height:243px;
  }

  #differences.zh .column-half:hover p.hover{
    /*display:none;*/
    /*animation: fadeIn ease 1s;*/
    min-height:199px;
  }

  /*#differences .column-half:hover p.onhover{
    display:block;
    animation: fadeIn ease 1s;
    position: relative;
    z-index: 2;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 45px;
    color: #000;
  }*/

  /*#differences .column-half:hover img.box-bg  {
      opacity: 0.5;
  }*/

  #differences .column-half .onhover{
    display:none;
    animation: fadeIn ease 1s;
  }

  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  #how-they-operate {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    scroll-margin-top: 150px;
  }

  #how-they-operate .column-half {
    width:25%;
    padding-right:15px
  }

  #how-they-operate .column-half.last {
    width:75%;
  }

  #how-they-operate .deadline-table {
    width:100%;
    color:#4D4D4D;
    font-size:15px;
  }

  #how-they-operate .column-half img {
    margin-left:auto;
    margin-right:auto;
    width:100%;
  }

  #how-they-operate .column-half img.fedex-icon {
    max-width:100%;
    margin-top:65px;
  }

  #how-they-operate .column-half img.ups-icon {
    margin-top:20px;
  }
  
  #how-they-operate .row-small {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom:40px;
    padding-top:10px;
  }

  #how-they-operate .row-small strong {
    font-size:18px;
  }

  #how-they-operate .deadline-table.last {
    text-align:center;
    border-top:0;
  }

  #how-they-operate .column-one-fourth {
    width:25%;
    display:inline-block;
  }

  #how-they-operate .column-three-fourth {
    width:75%;
    display:inline-block;
  }


  #how-they-operate p {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4D4D4D;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom: 10px;
  }

  #how-they-operate p strong {
    font-size: 18px;
  }

  #how-they-operate ul {
    list-style-type: none !important;
    padding-left: 20px;
    margin-top: 10px !important;
  }

  #how-they-operate ul li {
    font-size: 15px !important;
    color: #4D4D4D !important;
    margin-bottom: 20px;
  }

  ul li::before {
    content: '•';
    color: #00AF85;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  #how-they-operate h2 {
    text-align:left;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #which-is-better {
    max-width:1080px;
    margin-left:auto;
    margin-right:auto;
    padding-bottom:50px;
    scroll-margin-top: 150px;
  }

  #which-is-better h2 {
    text-align:center;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #which-is-better p strong {
    font-size: 18px;
    vertical-align: text-bottom;
    padding-left: 5px;
  }

  #which-is-better .icon {
    display: inline-block;
    max-height:31px;
    margin-top: 5px;
  }

  #which-is-better ul {
    list-style-type: none !important;
    padding-left: 40px;
    padding-right: 20px;
    margin-top: 0px !important;
    min-height:226px;
  }

  #which-is-better.zh ul {
    min-height:182px;
  }

  #which-is-better.ja ul {
    min-height:216px;
  }

   #which-is-better.ja ul {
    min-height:194px;
  }

  #which-is-better.ja .fulfillment ul {
    min-height:216px;
  }

  #which-is-better.zh .fulfillment ul {
    min-height: 150px;
  }

  #which-is-better .fulfillment ul {
    min-height:194px;
  }
  #which-is-better ul li {
    font-size: 15px !important;
    color: #4D4D4D !important;
  }

  #which-is-better .column-half {
    border: 2px solid #000;
    padding-right:0;
    padding-bottom:0px;
    margin-bottom:30px;
  }

  #which-is-better.kr .fulfillment ul {
    min-height:216px;
  }

  #which-is-better.kr ul {
    min-height:194px;
  }

  #which-is-better .column-half.last {
    border-left: 0
  }

  #which-is-better .column-half p {
    padding-left:20px;
  }


  @media only screen and (max-width:981px) {
    
    #inventory-storage {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #differences .column-half p.hover {
      margin-top:20px !important;
    }

    #inventory-storage h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #inventory-storage .column-half {
      width: 100%;
      padding: 0;
    }

    #inventory-storage .column-half.last {
      width: 100%;
      padding: 0;
    }

    #differences {
      padding-top:0px;
    }

    #differences h2 {
      font-size:28px;
      margin-top:20px
    }

    #differences .column-third {
      width:100%;
      display:inline-block;
      margin-right:0;
      margin-top: 20px;
    }

    #differences {
      padding-right:25px;
      padding-left:25px;
      padding-bottom:0
    }

    #differences .column-half:hover p.hover-desc {
      margin-bottom: 20px !important;
    }

    #differences p {
        margin-bottom: 15px;
    }

    #how-they-operate h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #how-they-operate .column-half {
      width: 100%;
      padding: 0;
    }

    #how-they-operate {
      padding-left:25px;
      padding-right:25px;

    }

    #how-they-operate .column-half img {
      margin-bottom: 25px;
    }

    #how-they-operate .column-half img.fedex-icon {
      margin-top: 0;
      max-width: 150px;
    }

    #how-they-operate .column-half img.ups-icon {
      margin-top: 0;
    }

    #how-they-operate .column-half.last {
      width: 100%;
      padding: 0;
    }

    #inventory-storage img {
      margin-top: 0
    }

    #inventory-storage {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #inventory-storage h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #differences .column-half {
      min-height: 350px;
      width:100% !important;
    }

    #differences .column-half p.hover {
      margin-bottom:0
    }

    #how-they-operate ul { 
      margin-bottom: 0 !important
    }

    #which-is-better .column-half {
      width:100% !important;
      border-left: 2px solid #000 !important;
    }

    #which-is-better {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom:20px;
    }

`;
import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { color, mainContainerWidth, breakpoint } from 'common/styledVars';
import { HeaderMedium, BaseTransparentP } from 'components/typography';
import { WidthWrapper } from 'components/wrappers';

export const PostsWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media ${mediaMin(breakpoint.desktop)} {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
`;

export const ArticlesContainer = styled.div`
  max-width: ${mainContainerWidth.desktop + 240}px;
  width: 100%;
  margin: 64px auto 0;

  @media ${mediaMin(breakpoint.desktop)} {
    margin: 120px auto 0;
  }
`;

export const LeftSection = styled.div`
  min-width: calc(50% - 48px);
  padding: 72px 0 91px;
  height: 540px;
  display: flex;
  flex-direction: column;

  ${BaseTransparentP} {
    max-width: 300px;
    margin-bottom: 32px;
  }

  ${HeaderMedium} {
    margin: 16px 0 auto;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    height: 700px;

    ${BaseTransparentP} {
      margin-bottom: 93px;
    }

    ${HeaderMedium} {
      margin: 42px 0 auto;
    }
  }
`;

export const RightSection = styled.div`
  flex-direction: column;
  padding: 74px 91px;

  ${HeaderMedium} {
    color: ${color.white};
    margin: auto 0 60px;
  }
`;

export const Wrapper = styled(WidthWrapper)`
  flex-direction: row;

  ${RightSection} {
    display: none;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    ${RightSection} {
      display: flex;
    }
  }
`;

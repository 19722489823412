import styled from 'styled-components';
import { mediaMin, mediaMax } from 'common/styledMixins';
import { breakpoint, zIndex, color } from 'common/styledVars';
import { BaseTransparentP, HeaderMedium, BaseP, HeaderSmall } from 'components/typography';
import { Image } from 'components/pages/BlogPost/styled';
import { WidthWrapper } from 'components/wrappers';
import {
  PostsWrapper,
} from 'components/pages/Blog/styled';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';

export const Clear = styled.div`
  clear:both;
`;

export const BlogRow = styled.div`
  max-width:1100px;
  margin-left:auto;
  margin-right:auto;
  padding-left:25px;
  padding-right:25px;;
  padding-top:50px;
  padding-bottom:50px;
  width:100%;

  .text-green {
    color:#00AF85;
  }

  p.text-small-center {
    font-size: 12px !important;
    text-align: center;
    margin-top: -40px !important;
  }
    .column-half {
      display:inline-block;
      width:28%;
      padding-right:4%;
      float:left;
    }

    .column-half.last {
      width:70%;
      padding-right:0;
    }

    h5 strong {
      color:#00af85;
      font-weight:600;
    }

    p strong {
      font-weight:600;
      color:#000;
    }

    li p {
      margin-bottom:0 !important;
    }

    img.no-top {
      margin-top:0 !important;
    }

    .no-bottom {
      margin-bottom:0 !important;
    }

    img.shadow-on {
      -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.08), 0 5px 26px 0 rgba(67,94,131,.15);
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.08), 0 5px 26px 0 rgba(67,94,131,.15);
    }

    ${PostsWrapper} {
      grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
    }

    .no-top {
      margin-top:0 !important;
    }

    .column-half p.small {
      font-size:13px;
      margin-bottom:0;
      margin-top:-20px;
      margin-bottom:20px;
    }

    .column-half p {
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      margin: auto;
      color: #a4b0ad;
      margin: 0 0 20px auto;
      line-height:1.7;
    }

    img.small-image {
      max-width:300px;
      margin-left:0 !important;
    }

    h5 {
      width: 100%;
      font-size: 20px;
      font-weight: 300;
      margin: auto;
      margin-bottom:30px;
      margin-top:30px;
    }

    h3 {
      font-size: 22px;
      margin-top: 10px;
      font-weight: 500;
    }

    img {
      width:100%;
    }

    ${Image} {
      margin:40px auto !important;
    }

    @media only screen and (min-width:1024px) {
      .column-half  p {
        font-size: 18px;
      }
      p {
        font-size: 18px;
      }
    }

    @media only screen and (max-width:1023px) {
      padding-bottom:0;
      .column-half {
        width:100% !important;
        padding-right:0 !important;
        margin-bottom:30px;
      }

      .SocialMediaShareButton {
        display:block !important;
        margin-bottom:20px;
      }
    }
`;

export const ShareButton = styled.button`
    padding: 20px 25px;
    border: 0;
    background: #00AF85;
    border-radius: 20px;
    max-width: 135px;
    text-align: left;
    color: #fff;
    margin-right:10px;

    img {
      display: inline-block;
      max-width: 20px;
      margin-right: 10px;
      float: left;
    }

    span {
      line-height: 1.4;
      font-size: 18px;
      float: left;
    }
`;

export const ReadNow = styled.button`
    padding: 10px 25px;
    border: 0;
    background: #00AF85;
    border-radius: 20px;
    max-width: 150px;
    text-align: left;
    color: #fff;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:20px;

    &:hover {
      cursor:pointer;
    }
    
    span {
      line-height: 1.4;
      font-size: 16px;
      float: left;
    }

    @media only screen and (max-width:1023px) {
      margin-left:0;
    }
`;

export const DateText = styled.p`
  color: #00af85 !important;
  font-size:20px !important;
  margin-bottom:5px !important;
`;

export const TitleText = styled.span`
  color: #000 !important;
  font-size:18px;
`;

export const BottomShare = styled.div`
  padding-top:50px;
  padding-bottom:0;

  .SocialMediaShareButton {
    display:inline-block;
  }

  .SocialMediaShareButton button:hover {
    cursor:pointer;
  }

  .column-half {
    width:18%;
  }

  p {
    margin-top:7px;
    color: #000 !important;
    font-size:18px;
  }
`;

export const SectionFull = styled.div`
  width:100%;
  overflow:hidden;

  h2 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 35px;
  }

  ${BlogRow} {
    padding-top:0;
  }

  @media only screen and (max-width:1023px) {
    max-height:100%;
  }
`;

export const OptinSection = styled.div`
  width:100%;
  max-height:580px;
  overflow:hidden;
  background:#00AF85 ;

  h2 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 35px;
  }

  ${BlogRow} {
    padding-top:30px;
    padding-bottom:30px;
  }

  .column-half {
    width:43%;
    padding-right:4%;
  }

  .column-half.last {
    width:53%;
    padding-right:0;
  }

  form {
    margin:0;
    padding:0;
  }

  h3 {
    font-size: 30px;
    color: #fff;
    font-weight: 300;
    margin-top: 40px;
  }

  img {
    max-width:330px;
  }

  p {
    line-height: 1.7;
    color: #fff !important;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  @media only screen and (max-width:1023px) {
    max-height:100%;
    .column-half {
      width:100%;
    }

    img {
      margin-left:auto;
      margin-right:auto;
    }

    h3 {
      margin-top:20px;
    }
    .column-half.last {
      margin-bottom: 0;
    }
`;


export const FirstTitle = styled.h5`
  margin-top:0 !important;
`;

export const OrderedList = styled.ol`
  margin-bottom:30px !important;
  list-style-type: decimal !important; 
  margin-left:30px !important;
  
  li {
    font-size:16px;
    line-height:1.5;
    margin-bottom:10px;
    color:#a4b0ad;
  }

  @media only screen and (min-width:1024px) {
    li {
      font-size:18px;
    }
  }
`;

export const UnorderedList = styled.ul`
  margin-bottom:30px !important;
  margin-top:30px !important;
  list-style-type: decimal !important;

  li {
    font-size:16px;
    line-height:1.5;
    margin-bottom:10px;
  }

  @media only screen and (min-width:1024px) {
    li {
      font-size:18px;
    }
  }

`;